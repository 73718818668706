import { useMemo } from "react";
import { Column } from "react-table";
import {
  IntlEnum,
  IntlMoney,
  MultiSelectColumnFilter,
} from "@common/components";
import { Conciliation } from "@reimbursement/conciliations/contracts/Conciliation";
import { getValueEnumObject } from "@shared/helpers";
import { EnumConciliationStatus } from "@reimbursement/conciliations/enums/EnumConciliationStatus";
import { ConciliationStatus } from "@reimbursement/conciliations/types/ConciliationStatus";
import useConciliationStatus from "@reimbursement/conciliations/hooks/useConciliationStatus";
import { formatDateToMonthYear } from "@common/helpers";
import { DetailConciliation } from "../DetailConciliation";

type IndustryFilter = {
  key: 'string';
  value: 'string';
}

export default function GridColumns(industries?: IndustryFilter[]) {


  type ConciliationRowData = {
    row: {
      original: Conciliation
    }
  }


  const statuses = useConciliationStatus().map((s: { name: string, code: ConciliationStatus }) => {
    return s.code;
  });

  const columns: Column<Conciliation>[] = useMemo(() => [
    {
      Header: <IntlEnum name="conciliation_grid" value="id" />,
      accessor: "id",
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="external_id" />,
      accessor: "externalId",
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="industry_name" />,
      accessor: "industry_name",
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="industry_code" />,
      accessor: "industry_code",
      options: industries || [],
      Filter: MultiSelectColumnFilter,
      isVisible: false,
      multiple: true,
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="status" />,
      accessor: "status",
      isVisible: false,
      Filter: MultiSelectColumnFilter,
      options: statuses,
      Cell: ({ value }) => getValueEnumObject<ConciliationStatus>(EnumConciliationStatus, value.toUpperCase()),
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="month" />,
      accessor: "month",
      isVisible: false,
      Cell: ({ value }) => formatDateToMonthYear(value),
      width: 100,
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="totalAmount" />,
      accessor: "totalAmount",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="totalValue" />,
      accessor: "totalValue",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="conciliatedAmount" />,
      accessor: "conciliatedAmount",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="conciliatedValue" />,
      accessor: "conciliatedValue",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="differentAmount" />,
      accessor: "differentAmount",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="differentValue" />,
      accessor: "differentValue",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: "",
      id: "detalhes",
      width: '10px',
      Cell: ({ row }: ConciliationRowData) => (
        DetailConciliation({
          conciliation: row.original
        })
      ),
    },
  ], []);

  return columns;
}
