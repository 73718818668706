import { TextField } from "@common/components/Form";
import { Autocomplete, Stack } from '@mui/material';
export function MultiSelectColumnFilter({column: { options, filterValue, setFilter, customInput, multiple = true }}: any){  

  return (
    <Stack spacing={2} sx={{ width: 'auto' }}>
      <Autocomplete
        multiple={multiple}
        freeSolo={customInput}
        limitTags={1}
        size="small"
        id="tags-standard"
        options={options}
        getOptionLabel={(option:any) => option.description || option}   
        onChange={(event, value)=> {
          setFilter(value);
        }}

        renderInput={(params) => (
          <TextField           
            {...params}
            value={filterValue || ""}
            variant="standard"
          />
        )}
      />
    </Stack>
  );
}
