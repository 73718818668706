import { useMemo } from "react";
import { ReimbursementStatus } from "../types/ReimbursementStatus";
import { EnumReimbursementStatus } from "../enums/EnumReimbursementStatus";

export function convertSelectionOption(status: ReimbursementStatus[]) {
  return status.map((status: any) => Object({ value: status.code, description: status.name }));
}

export type StatusOption = {
  code: ReimbursementStatus
  name: string
}

export default function useReimbursementStatus(): StatusOption[] {
  return useMemo(
    () => [
      {
        code: EnumReimbursementStatus.EXCLUIDO,
        name: "Excluido"
      },
      {
        code: EnumReimbursementStatus.VALIDACAO,
        name: "Em validação"
      },
      {
        code: EnumReimbursementStatus.VALIDADO,
        name: "validado"
      },
    ], []);
}