import { Grid } from "@mui/material";
import {
  GridView,
} from "@common/components";
import { Conciliation } from "@reimbursement/conciliations/contracts/Conciliation";
import GridColumns from "@reimbursement/conciliations/components/GridConciliation/GridColumns";
import { useAllConciliations } from "@reimbursement/conciliations/hooks/useAllConciliations";
import { ClearFilterButton } from "@shared/components/ClearFilterButton";
import { useGridView } from "@common/context/GridView/useGridView";
import { FilterTopReimbursement } from "@shared/components/FilterTopReimbursement";
import { useIndustries } from "@reimbursement/shared/hooks/useIndustries";
import { useEffect, useState } from "react";

const defaultFilterTop = {
  begin_date: null,
  end_date: null
}


export default function GridConciliation() {

  const { data: industries, isLoading } = useIndustries();
  const [industriesFilter, setIndustriesFilter] = useState([]);

  useEffect(() => {
    const formatedIndustries = industries?.edges?.map((i: any) => {
      return i.node.code;
    });

    setIndustriesFilter(formatedIndustries);

  }, [industries]);
  const RenderGridBatch = () => {
    const { clearFilters, setClearFilters } = useGridView();
    const columns = GridColumns(industriesFilter);
    setClearFilters(false);
    return (
      <Grid key={String(clearFilters)}>
        {(isLoading && !industriesFilter) ? <></> :
          (<>
            <FilterTopReimbursement {...defaultFilterTop} />
            <ClearFilterButton />
            <Grid />
            <GridView<Conciliation>
              columns={columns}
              hook={useAllConciliations}
              paginated
              hiddenColumns={[
                "id", 
                "externalId", 
                "conciliatedValue", 
                "totalValue", 
                "differentValue",
                "industry_name",
                ""
              ]}
              tableIdentifier="CO" />
            </>
            )}
      </Grid>
    )
  }

  return (<RenderGridBatch />)
}
