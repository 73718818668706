import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useJwtUser } from '@common/hooks';

export function ProfileMenu() {
  const { user, logout } = useJwtUser();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const name = React.useCallback((): string => {
    const name = user?.name ?? '';
    const arrayNames = name
      .split(' ')
      .map((string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      });

    if (arrayNames.length === 1) {
      return arrayNames[0];
    }

    return `${arrayNames[0]} ${arrayNames[arrayNames.length - 1]}`
  }, [user])

  const nameAcronym = React.useCallback((): string => {
    const name = user?.name;

    if (!name) {
      return "";
    }

    if (name.trim() === "") {
      return "";
    }

    const arrayNames = name.split(" ");

    if (arrayNames.length < 1) {
      return "";
    }

    const firstName = arrayNames[0];
    return firstName.slice(0, 2).toUpperCase();
  }, [user]);


  return <Box sx={{ flexGrow: 0 }} >
    <Tooltip title={user?.name ?? ''}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar alt="Remy Sharp">
          <Typography
            variant="body1"
            noWrap
            sx={{
              textAlign: 'center',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#00000',
              textDecoration: 'none',
            }}
          >
            {nameAcronym()}
          </Typography>
        </Avatar>
      </IconButton>
    </Tooltip>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem key={'name'}>
        <Typography sx={{ textAlign: 'center' }}>Bem vindo, {name()}</Typography>
      </MenuItem>
      <MenuItem key={'logout'} onClick={() => {handleCloseUserMenu(); logout()}} >
        <Typography sx={{ textAlign: 'center' }}>Sair</Typography>
      </MenuItem>
    </Menu>
  </Box>
}
