import { Button, IntlEnum, IntlMoney } from "@common/components";
import { Dialog, DialogActions, DialogContent, DialogContentText, FormGroup, IconButton, Typography } from "@mui/material"
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { AcceptReimbursementInput, useAcceptReimbursements } from "@reimbursement/batch/hooks/useAcceptReimbursement";
import { SubmitHandler, useForm } from "react-hook-form";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";
import { errorResolver } from "@reimbursement/helpers/ReimbursementErrorResolver";
import { Batch } from "@reimbursement/batch/contracts/Batch";
import { useGridView } from "@common/context/GridView/useGridView";

const BatchAcceptanceModal = (data: Batch) => {

  enum ResponseStatus {
    SUCCESS,
    FAIL,
    NULL,
  }

  const { setClearFilters, setGridFilters } = useGridView();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(ResponseStatus.NULL);
  const [responseText, setResponseText] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setClearFilters(true);
    setGridFilters([]);
  }
  const { request } = useReimbursementAPI();

  const id: string = data.id;

  const params: AcceptReimbursementInput = {
    input: {
      id: id
    }
  }

  const { handleSubmit } = useForm<any>(
    {
      mode: 'onChange',
      reValidateMode: 'onChange',
    }
  );

  const OnSubmit: SubmitHandler<any> = async () => {
    setResponse(ResponseStatus.NULL);
    try {
      const data = await useAcceptReimbursements({
        input: params.input,
      }, request);
      setResponse(data ? ResponseStatus.SUCCESS : ResponseStatus.FAIL)
      setResponseText(data ? "Lote validado com sucesso" : "Erro ao validar lote")
    } catch (e: any) {
      setResponse(ResponseStatus.FAIL);
      setResponseText(errorResolver(e.response.errors[0].extensions.code));
    }
  }
  return (
    <>
      <Button
        type="button"
        size="medium"
        className="float-right"
        onClick={() => { handleOpen() }}
        disabled={data.status !== 'VALIDACAO'}
        data-testid={'validate-modal-button'}
      >
        <IntlEnum name="reimbursement_grid" value="acceptance" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <DialogContent>
          <IconButton
            style={{ position: "absolute", top: "15px", right: "10px" }}
            onClick={handleClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText sx={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}>
            {data?.industry_name + ' - Lote: ' + data.externalId}
          </DialogContentText>
          <FormGroup sx={{ flexDirection: "row", mt: 3 }}>
            <Typography sx={{ color: "#000", fontWeight: "bold" }}>
              {"Validação do lote de ressarcimento: "}
            </Typography>
            <Typography>
              {data.externalId}
            </Typography>
          </FormGroup>
          <FormGroup sx={{ flexDirection: "row" }}>
            <Typography sx={{ color: "#000", fontWeight: "bold" }}>
              {"Valor total final: "}
            </Typography>
            <Typography>
              <IntlMoney value={data.predicted_value - data.deduction} />
            </Typography>
          </FormGroup>
          <FormGroup sx={{ flexDirection: "row" }}>
            {response !== ResponseStatus.NULL && <Typography sx={{
              color: response === ResponseStatus.FAIL ? "red" : "green",
              fontWeight: "bold"
            }}>
              {responseText}
            </Typography>}
          </FormGroup>
        </DialogContent>
        <form onSubmit={handleSubmit(OnSubmit)}>
          <DialogActions style={{ justifyContent: 'end' }} className="mb-5 mr-5">
            <Button type="submit" sx={{
              display: response === ResponseStatus.SUCCESS ? "none" : "default"
            }}>
              <IntlEnum name="reimbursement_grid" value="acceptance" />
            </Button>
            <Button onClick={handleClose} sx={{ backgroundColor: "gray" }}>
              {(data.status === 'VALIDADO' || response === ResponseStatus.SUCCESS) ?
                <IntlEnum name="reimbursement_grid" value="close" /> :
                <IntlEnum name="reimbursement_grid" value="cancel" />}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default BatchAcceptanceModal;