import { Link } from 'react-router-dom';
import { useJwtUser } from '@common/hooks';
import { IntlEnum } from '@common/components';
import { styled, useTheme } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalShipping from '@mui/icons-material/LocalShipping';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Summarize from '@mui/icons-material/Summarize'

const menus = [
  {
    menu: { label: <IntlEnum name="menu_monitoro" value="menu_monitoro" /> },
    path: '/',
    roles: ['master', 'wholesaler', 'wholesaler_branch'],
    routes: [
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="wholesalers" /> },
        path: '/we/orders',
        active: true,
        icone: <LocalShipping/>,
        roles: ['master', 'wholesaler', 'wholesaler_branch']
      },
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="canal_autorizador" /> },
        path: '/ca/orders',
        active: true,
        icone: <ShoppingBag/>,
        roles: ['master', 'wholesaler', 'wholesaler_branch']
      },
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="reimbursement" /> },
        path: '/reimbursement/batches',
        id: 'reimbursement',
        active: process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU ? process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU === "true" : false,
        icone: <AttachMoney/>,
        roles: ['master', 'wholesaler']
      },
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="conciliations" /> },
        path: '/conciliations',
        id: 'conciliation',
        active: process.env.REACT_APP_ENABLE_CONCILIATION_MENU ? process.env.REACT_APP_ENABLE_CONCILIATION_MENU === "true" : false,
        icone: <Summarize/>,
        roles: ['master', 'wholesaler']
      }
    ]
  },
];

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export function NavBar({ handleDrawerClose, open }: any) {
  const theme = useTheme();
  const { user } = useJwtUser();

  return <Drawer
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
      },
    }}
    variant="persistent"
    anchor="right"
    open={open}
  >
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </DrawerHeader>
    <Divider />
    {menus.map((route) =>
      user && user.hasAnyOfTheseSsoRoles(...route.roles) ? (
        <>
          <List key={`${route.path}-nav-list`}>
            <h5 className='px-3 mb-3 lg:mb-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900'>
              {route?.menu?.label}
            </h5>
            <ul>
              {route.routes.filter(subRoute => subRoute.active).map((subRoute) =>
                user && user.hasAnyOfTheseSsoRoles(...subRoute.roles) ? (
                  <ListItem key={`${subRoute.path}-nav-list-item`} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {subRoute.icone}
                      </ListItemIcon>
                      <Link
                        to={subRoute.path}
                      >
                        <ListItemText primary={subRoute?.menu?.label} />
                      </Link>
                    </ListItemButton>
                  </ListItem>
                ) : null
              )}
            </ul>
          </List>
          <Divider />
        </>
      ) : null
    )}
  </Drawer>
}