import { Link } from 'react-router-dom';
import { Card as DefaulCard, CardContent, Typography, IconButton } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import React from 'react';

type CardProps = {
  text: string,
  icone: React.ReactElement,
  local: string
}

const Card = ({ text, icone, local }: CardProps) => {
  return (
    <DefaulCard
      className="transition-transform transform hover:scale-105 focus-within:scale-105 shadow-md hover:shadow-lg focus-within:shadow-lg cursor-pointer"
    >
      <Link
        to={local}
      >
        <CardContent>
          <div className="flex items-center justify-between">
            <IconButton
              aria-label="Texto"
              className="text-gray-500 hover:text-blue-500"
            >
              {icone ?? <TextFieldsIcon />}
            </IconButton>
            <Typography variant="h6" className="text-gray-800">
              {text}
            </Typography>
          </div>
          <Typography variant="body2" className="text-gray-600 mt-2">
            {text}
          </Typography>
        </CardContent>
      </Link>
    </DefaulCard>
  );
};

export default Card;