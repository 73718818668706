import { Grid } from "@mui/material";
import {
  GridView,
} from "@common/components";
import { Batch } from "@reimbursement/batch/contracts/Batch";
import GridColumns from "@reimbursement/batch/components/GridBatch/GridColumns";
import { useReimbursements } from "@reimbursement/batch/hooks/useReimbursements";
import { ClearFilterButton } from "@shared/components/ClearFilterButton";
import { useGridView } from "@common/context/GridView/useGridView";
import { FilterTopReimbursement } from "@shared/components/FilterTopReimbursement";
import { useIndustries } from "@reimbursement/shared/hooks/useIndustries";
import { useEffect, useState } from "react";

const defaultFilterTop = {
  begin_date: null,
  end_date: null
}

export default function GridBatch() {

  const { data: industries, isLoading } = useIndustries();
  const [industriesFilter, setIndustriesFilter] = useState([]);

  useEffect(() => {
    const formatedIndustries = industries?.edges?.map((i: any) => {
      return i.node.code;
    });

    setIndustriesFilter(formatedIndustries);

  }, [industries]);

  const RenderGridBatch = () => {
    const { clearFilters, setClearFilters } = useGridView();
    const columns = GridColumns(industriesFilter);
    setClearFilters(false);
    return (
      <Grid key={String(clearFilters)}>
        {(isLoading && !industriesFilter) ? <></> :
          (<><FilterTopReimbursement {...defaultFilterTop} /><ClearFilterButton /><Grid /><GridView<Batch>
            columns={columns}
            hook={useReimbursements}
            paginated
            hiddenColumns={["id", 
              "externalId", 
              "industry_name", 
              "division", 
              "visited", 
              "rating"
            ]}
            tableIdentifier="RE" /></>)}
      </Grid>
    )
  }

  return (<RenderGridBatch />)
}
