import { useMemo } from "react";
import { ConciliationStatus } from "../types/ConciliationStatus";
import { EnumConciliationStatus } from "../enums/EnumConciliationStatus";

export function convertSelectionOption(status: ConciliationStatus[]) {
  return status.map((status: any) => Object({ value: status.code, description: status.name }));
}

export type StatusOption = {
  code: ConciliationStatus
  name: string
}

export default function useConciliationStatus(): StatusOption[] {
  return useMemo(
    () => [
      {
        code: EnumConciliationStatus.A_CONCILIAR,
        name: "A Conciliar"
      },
      {
        code: EnumConciliationStatus.CONCILIADO,
        name: "Conciliado"
      },
      {
        code: EnumConciliationStatus.CONCILIANDO,
        name: "Conciliando"
      },
      {
        code: EnumConciliationStatus.CONCLUINDO,
        name: "Concluindo"
      },
      {
        code: EnumConciliationStatus.CORTANDO,
        name: "Cortando"
      },
      {
        code: EnumConciliationStatus.ERRO,
        name: "Erro"
      },
    ], []);
}