import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IntlEnum, Button, TextField } from "@common/components";
import { useExportButton, useTableFilters } from "@common/context/GridView/useGridView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { SnackbarProps } from "@mui/material";
import { Alert } from "@common/components/Form/Alert";
import { useState } from "react";
import { SnackBar } from "@common/components/Form/SnackBar";
import { grey } from "@mui/material/colors";
import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { SendExportCA } from "@ca/orders/hooks/useExportOrders";
import { SendExportWE } from "@we/orders/hooks/useExportOrders";
import { SendExportReimbursementBatchDetails } from "@reimbursement/batch/hooks/useExportReimbursementBatchDetails";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";
import { sendExportReimbursementSheet } from "@reimbursement/batch/hooks/useExportReimbursementSheet";
import { sendExportConciliationData } from "@reimbursement/batch/hooks/useExportConciliationData";

interface EmailDialogProps {
  open: boolean;
  onHandleClick: Function;
  serviceType: string;
  modalText?: string | JSX.Element;
  userEmail?: string;
  recordId?: string;
  input?: any
}

export type EmailDialogTypeProps = {
  email: string;
};

const schema = yup.object({
  email: yup
    .string()
    .email('Este campo deve conter um e-mail válido.')
    .required('Este campo é obrigatório')
});

export function EmailDialog({
  open,
  onHandleClick,
  serviceType,
  modalText,
  userEmail,
  recordId,
  input,
}: EmailDialogProps) {

  const [openSnack, setOpenSnack] = useState(false)
  const { tableFilters } = useTableFilters();
  const { setDisableExportButton } = useExportButton();
  const [alertProps, setAlertProps] = useState({});
  const { request } = useStoresGateway();
  const { request: reimbursementRequest } = useReimbursementAPI();

  const text = modalText ?? <IntlEnum name="export_data" value="dialog_message" />;

  const closeSnackbar = () => {
    setOpenSnack(false)
  }

  const snackProps: SnackbarProps = {
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    children: Alert(alertProps),
    open: openSnack,
    onClose: closeSnackbar
  };

  const handleClose = () => {
    onHandleClick(false);
  };

  let { handleSubmit, control, formState: { errors, isValid } } = useForm<EmailDialogTypeProps>(
    {
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
        email: userEmail
      }
    }
  );

  const OnSubmit: SubmitHandler<EmailDialogTypeProps> = async (data: EmailDialogTypeProps) => {
    let response = null;

    switch (serviceType) {
      case 'CA':
        response = await SendExportCA(data.email, tableFilters, request)
        break;
      case 'WE':
        response = await SendExportWE(data.email, tableFilters, request)
        break;
      case 'RE':
        const params = {
          input: {
            email: data.email,
            ids: recordId ? [recordId] : [""],
          }
        }
        try {
          response = await SendExportReimbursementBatchDetails(params, reimbursementRequest)
        }
        catch (e: any) {
          const message = e.response?.errors[0]?.message;
          response = {
            status: 'FAILED',
            message: 'Erro ao agendar exportação: ' + message,
          }
        }
        break;
      case 'RE_SHEET':
        const params2 = {
          input: {
            email: data.email,
            conciliationId: recordId ?? ''
          }
        }
        try {
          response = await sendExportReimbursementSheet(params2, reimbursementRequest)
        }
        catch (e: any) {
          const message = e.response?.errors[0]?.message;
          response = {
            status: 'FAILED',
            message: 'Erro ao agendar exportação: ' + message,
          }
        }
        break;
      case 'RE_CONCILIA':
        const params3 = {
          input: {
            email: data.email,
            conciliationId: recordId ?? '',
            type: input.type
          }
        }
        try {
          response = await sendExportConciliationData(params3, reimbursementRequest)
        }
        catch (e: any) {
          const message = e.response?.errors[0]?.message;
          response = {
            status: 'FAILED',
            message: 'Erro ao agendar exportação: ' + message,
          }
        }
        break;
      default:
        return
    }

    if (response.status === 'FAILED') {
      setAlertProps({
        severity: 'error',
        sx: { width: '100%' },
        children: response.message,
      })
    } else {
      setAlertProps({
        severity: 'success',
        sx: { width: '100%' },
        children: response.message || "Exportação agendada",
      })
    }

    setOpenSnack(true)
    setDisableExportButton(true)
    handleClose()
  }

  const greyColor = grey[400]

  return (
    <>
      <SnackBar {...snackProps} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <form onSubmit={handleSubmit(OnSubmit)}>
          <DialogContent>
            <DialogContentText className="text-center py-5 px-5">
              {text}
            </DialogContentText>
            <TextField
              control={control}
              controllerName="email"
              name="email"
              errorMessage={errors.email?.message}
              label={<IntlEnum name="export_data" value="email_placeholder" />}
              fullWidth
              variant="standard"
              autoFocus
              margin="dense"
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }} className="mb-5">
            <Button type="submit" disabled={!isValid}>
              <IntlEnum name="export_data" value="prepare_export_button" />
            </Button>
            <Button onClick={handleClose} sx={{ backgroundColor: greyColor }}>
              <IntlEnum name="export_data" value="prepare_export_cancel" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}