import React from "react";
import { ButtonExport, EmailDialog } from "@shared/components";
import { IntlEnum } from "@common/components";
import { useJwtUser } from "@common/hooks";

type Type = 'funcional' | 'wholesaler' | 'conciliation' | 'history';
type TypeConciliationDat =  'wholesaler' | 'conciliation' | 'history';

export type ExportParams = {
  recordId: string;
  status: string;
  type: Type
}


export function Export(params: ExportParams) {
  const [open, setOpen] = React.useState(false)
  const text = <IntlEnum name="conciliation_detail_grid" value="export_data" />;
  const { user } = useJwtUser();
  const texts = {
    funcional: 'export_conciliation_funcional',
    wholesaler: 'export_conciliation_wholesaler',
    conciliation: 'export_conciliation_conciliation',
    history: 'export_conciliation_history'
  }

  const service = () => {
    if (params.type == 'funcional') {
      return 'RE_SHEET';
    }
    return 'RE_CONCILIA';
  }

  const onType = {
    wholesaler: 'informado',
    conciliation: 'conciliacao',
    history: 'historico'
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ButtonExport
        data-testid={'export-modal-button'}
        disabled={params.status === 'EXCLUIDO'}
        onClick={handleClickOpen}
        value={texts[params.type]}
      />
      <EmailDialog
        open={open}
        onHandleClick={setOpen}
        serviceType={service()}
        modalText={text}
        userEmail={user?.email}
        recordId={params.recordId}
        input={{ type: onType[params.type as TypeConciliationDat] }}
      />
    </>
  )
}