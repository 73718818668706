import React from "react";
import { IntlEnum, IntlMoney, MaskCnpj } from "@common/components";
import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Dialog, DialogContent, DialogContentText, IconButton } from "@mui/material"
import { Conciliation } from "@reimbursement/conciliations/contracts/Conciliation";
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material";
import { formatDateToMonthYear } from "@common/helpers";
import { LightTooltip } from "@common/components/LightTooltip";
import InfoIcon from '@mui/icons-material/Info';
import { Export } from "../Export";
import { EnumConciliationStatus } from "@reimbursement/conciliations/enums/EnumConciliationStatus";
import { EnumConciliationStatusType } from "@reimbursement/conciliations/enums/EnumConciliationStatusType";
export type DetailConciliationParams = {
  conciliation: Conciliation
}

export function DetailConciliation({ conciliation }: DetailConciliationParams) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <Button
        type="button"
        size="medium"
        className="float-right"
        onClick={() => { handleOpen() }}
        data-testid={'detail-modal-button'}
      >
        <IntlEnum name="conciliation_detail_grid" value="conciliation_detail" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <DialogContent>
          <IconButton
            style={{ position: "absolute", top: "15px", right: "10px" }}
            onClick={handleClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText sx={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}>
            Detalhes
          </DialogContentText>
          <Stack spacing={1} padding={2} marginTop={2} marginBottom={2} bgcolor={'#e1e1e3'}>
            <DialogContentText>
              CNPJ: {MaskCnpj({ value: conciliation.wholesalerCode })}
            </DialogContentText>
            <DialogContentText>
              Mês: {formatDateToMonthYear(conciliation.month)}
            </DialogContentText>
          </Stack>
          <TableContainer component={Paper} sx={{mb: 2, mr: 0, emptyCells: "hide"}}>
            <Table sx={{ minWidth: 300, maxWidth: 600}} aria-label="detail table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right" className="text-xs">Notas</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Valor Ressarcimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Quantidade de notas únicas, com status de "OK", "só consta na Funcional" ou "só consta no distribuidor"'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    {conciliation.totalInvoices}
                  </TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Verifica qual é a maior quantidade entre o informado pela planilha do distribuidor e o que está registrado na Funcional, para todos os status, e depois soma todas essas quantidades'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    {conciliation.totalAmount}</TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Verifica qual é o maior valor de ressarcimento entre o informado pela planilha do distribuidor e o que está registrado na Funcional, para todos os status, e depois soma todos esses valores'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    <IntlMoney value={conciliation.totalValue} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Distribuidor</TableCell>
                  <TableCell align="right">{conciliation.informedInvoices}</TableCell>
                  <TableCell align="right">{conciliation.informedAmount}</TableCell>
                  <TableCell align="right">{conciliation.informedValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Funcional</TableCell>
                  <TableCell align="right">{conciliation.registeredInvoices}</TableCell>
                  <TableCell align="right">{conciliation.registeredAmount}</TableCell>
                  <TableCell align="right"><IntlMoney value={conciliation.registeredValue} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Diferença</TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Quantidade de notas únicas, com status de "só consta na Funcional" ou "só consta no distribuidor"'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    {conciliation.differentInvoices}</TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Soma das quantidades de itens com status "Nota só consta na Funcional/Distribuidor" e "Produto só consta na Funcional/Distribuidor"'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    {conciliation.differentAmount}</TableCell>
                  <TableCell align="right">
                    <LightTooltip title='Diferença entre a soma do valor de ressarcimento registrada na Funcional e a soma do valor de ressarcimento informada pela planilha do distribuidor'>
                      <InfoIcon className="p-1" />
                    </LightTooltip>
                    <IntlMoney value={conciliation.differentValue} /></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} marginY={4} marginLeft={4}>
            <Grid item xs={2} sm={4} md={4}>
              <Export recordId={conciliation.id} status="fd" type="funcional" />
            </Grid>
            {conciliation.status != EnumConciliationStatusType.A_CONCILIAR && <Grid item xs={2} sm={4} md={4}>
              <Export recordId={conciliation.id} status="fd" type="wholesaler" />
            </Grid>}
            {conciliation.status != EnumConciliationStatusType.A_CONCILIAR && <Grid item xs={2} sm={4} md={4}>
              <Export recordId={conciliation.id} status="fd" type="conciliation" />
            </Grid>}
          </Grid>
          {conciliation.status != EnumConciliationStatusType.A_CONCILIAR && <Grid container spacing={2} wrap="nowrap" sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Grid item xs={8} sm={8} md={8}>
              <Export recordId={conciliation.id} status="fd" type="history" />
            </Grid>
          </Grid>}
        </DialogContent>
      </Dialog>
    </>
  )
}