import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { FilteredColumn } from "@common/components";
import { SortFieldType } from "@ca/orders/types/SortField";
import { Conciliation } from "../contracts/Conciliation";
import { CursorPaginated } from "@types";
import { convertReimbursementResponseCursorPaginated } from "@shared/helpers/ConvertReimbuesementResponseCursorPaginated";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";
import { ConciliationNode } from "../contracts/ConciliationNode";
import { ConciliationStatus } from "../types/ConciliationStatus";

export interface allConciliationsArgsFilterInput {
  wholesaler_code?: string,
  industry?: {
    codes?: string[];
  }
  status?: ConciliationStatus;
  startDate?: string;
  endDate?: string;
}

interface UseConciliationOptions {
  before?: string,
  after?: string,
  first?: number,
  last?: number,
  limit: number,
  offset: number,
  filter?: allConciliationsArgsFilterInput | undefined;
  gridFilters?: FilteredColumn[];
}

export interface SortFields {
  field: SortFieldType,
  direction: 'ASC' | 'DESC'
}

export function setFilters(options: allConciliationsArgsFilterInput) {
  const filter: allConciliationsArgsFilterInput = options || {};

  return filter;
}

export function useAllConciliations(options: UseConciliationOptions) {

  const filter: allConciliationsArgsFilterInput = {};

  options.offset = 0;
  options.limit = 10;
  options.filter = filter;

  if (options.gridFilters) {
    options.gridFilters.forEach((filteredColumn: FilteredColumn) => {
      if (filteredColumn.id === 'currentPage') {
        if (filteredColumn.value === 1) {
          options.offset = 0;
          return;
        }
        options.offset = (filteredColumn.value - 1) * options.limit;
      }
      if (filteredColumn.id === 'industry_code' && filteredColumn.value.length > 0) {
        options!.filter!.industry! = {
          codes: filteredColumn.value
        }
        return;
      }
      if (filteredColumn.id === 'status' && filteredColumn.value.length > 0) {
        options!.filter!.status = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'end_date') {
        options!.filter!!.endDate = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'begin_date') {
        options!.filter!.startDate = filteredColumn.value
        return;
      }
    });
  }

  const { request } = useReimbursementAPI();

  return useQuery("conciliations:" + JSON.stringify(options), async () => {
    const { allConciliations } = await request(
      gql`
        query allConciliations(
            $filter: ConciliationFilterInput,
            $offset: Int
            $limit: Int
        ) {
          allConciliations(
        filters: $filter
        offset: $offset
        limit: $limit
        ) {
          edges {
            cursor
            node {
              id
              externalId
              wholesaler {
                code
              }
              status
              month
              conciliatedAmount
              totalAmount
              totalValue
              industry {
                name
                code
              }
              conciliatedInvoices
              differentInvoices
              informedInvoices
              registeredInvoices
              totalInvoices
              conciliatedAmount
              differentAmount
              informedAmount
              conciliatedValue
              differentValue
              informedValue
              totalValue
              registeredAmount
              registeredValue
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            pageCount
            totalCount
          }
        }
        }`, options
    );

    var result = {
      ...allConciliations.pageInfo,
      data: allConciliations.edges,
    }

    var paginationParams = {
      limit: options.limit,
      offset: options.offset,
    }

    const paginated = convertReimbursementResponseCursorPaginated<ConciliationNode>(result, paginationParams);
    return refactorResult(paginated);
  }, {
    refetchOnWindowFocus: false
  });
}

export function refactorResult(data: CursorPaginated<ConciliationNode>) {
  const formatedData: Conciliation[] = [];

  data.edges.map((d: ConciliationNode) => {
    formatedData.push({
      id: d.node.id,
      externalId: d.node.externalId,
      industry_code: d.node.industry?.code || '',
      industry_name: d.node.industry?.name || '',
      wholesalerCode: d.node.wholesaler?.code || '',
      status: d.node.status,
      month: d.node.month,
      conciliatedInvoices: d.node.conciliatedInvoices,
      differentInvoices: d.node.differentInvoices,
      informedInvoices: d.node.informedInvoices,
      registeredInvoices: d.node.registeredInvoices,
      totalInvoices: d.node.totalInvoices,
      conciliatedAmount: d.node.conciliatedAmount,
      differentAmount: d.node.differentAmount,
      informedAmount: d.node.informedAmount,
      conciliatedValue: d.node.conciliatedValue,
      differentValue: d.node.differentValue,
      informedValue: d.node.informedValue,
      totalValue: d.node.totalValue,
      totalAmount:d.node.totalAmount,
      registeredAmount: d.node.registeredAmount,
      registeredValue: d.node.registeredValue,
    });
  });

  return {
    ...data,
    edges: formatedData,
  }
}
