import { gql } from "graphql-request";

type Type = 'informado' | 'historico' | 'conciliacao'
export interface ExportConciliationDataInput {
  input: {
    email: string;
    conciliationId: string;
    type: Type
  }
}

export async function sendExportConciliationData(options: ExportConciliationDataInput, request: any) {

  const { exportConciliationData } = await request(
    gql`
    mutation exportConciliationData($input:ExportConciliationDataInput!) {
      exportConciliationData(input:$input)
      }`, options
  )

  return exportConciliationData
}
