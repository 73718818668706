import { IntlEnum, Button, ButtonProps } from "@common/components";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


type ButtonExportProps = ButtonProps & {
  value?: string
}

export function ButtonExport({value, ...props}: ButtonExportProps) {
  value = value ?? 'export_button'
  return (
    <Button 
      type="button"
      size="medium"
      className="float-right"
      {...props}
    >
      <CloudUploadIcon className="mr-2" />
      <IntlEnum name="export_data" value={value}/>
    </Button>
  )
}
