import { IntlEnum, Button } from "@common/components";
import { useGridView, useOpenDialogColumns } from "@common/context/GridView/useGridView";
import ClearIcon from '@mui/icons-material/Clear';
import { ButtonColumnsConfig } from "./ButtonColumnsConfig";

export function ClearFilterButton(props: any) {
  const { setClearFilters, setGridFilters } = useGridView();
  const { setOpenDialogColumns } = useOpenDialogColumns()

  const handleClickColumnsSettingOpen = () => {
    setOpenDialogColumns(true)
  }
  return (
    <>
      <Button
        type="button"
        size="medium"
        className="float-right"
        onClick={() => {
          setClearFilters(true)
          setGridFilters([])
        }}
      >
        <ClearIcon className="mr-2" />
        <IntlEnum name="filter" value="clear_filters" />
      </Button>
      <ButtonColumnsConfig onClick={handleClickColumnsSettingOpen} />
    </>
  )
}
