import { IRoute } from "@src/routes";
import ListConciliationsPage from "./pages/ListConciliationsPage";

const routes: IRoute[] = [
    {
        menu: { label: "Conciliações" },
        path: "",
        key: "CONCILIARIONS_LIST",
        exact: true,
        component: ListConciliationsPage,
    }
];

export default routes;
