import { useJwtUser } from "@common/hooks";
import { Stack } from "@mui/material";
import LocalShipping from '@mui/icons-material/LocalShipping';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Summarize from '@mui/icons-material/Summarize'
import Card from "@common/components/Card";

const cards = [
  {
    active: true,
    card: <Card key="card-we/orders" text={'Ol Wholesalers'} icone={<LocalShipping />} local={'/we/orders'} />,
    roles: ['master', 'wholesaler', 'wholesaler_branch']
  },
  {
    active: true,
    card: <Card key="card-ca/orders" text={'Canal Autorizador'} icone={<ShoppingBag />} local={'/ca/orders'} />,
    roles: ['master', 'wholesaler', 'wholesaler_branch']
  },
  {
    active: process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU ? process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU === "true" : false,
    card: <Card key="card-/reimbursement/batches" text={'Ressarcimento'} icone={<AttachMoney />} local={'/reimbursement/batches'} />,
    roles: ['master', 'wholesaler']
  },
  {
    active: process.env.REACT_APP_ENABLE_CONCILIATION_MENU ? process.env.REACT_APP_ENABLE_CONCILIATION_MENU === "true" : false,
    card: <Card key="card-conciliations" text={'Conciliação'} icone={<Summarize />} local={'/conciliations'} />,
    roles: ['master', 'wholesaler']
  }
];

export default function Main() {
  const { user } = useJwtUser();

  return (
    <>
      <main className="container mx-auto px-4 py-12">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Bem-vindo ao Monitoro Distribuidores,  {user?.name}</h2>
          <p className="text-lg text-gray-600 leading-relaxed">
            O Monitoro Distribuidores é um portal que permite aos distribuídores visualizarem as informações de pedidos e suas pendências,como falta de nota ou falta de retorno e também a tela de ressarcimento, onde é possível fazer a conferência dos valores de ressarcimento.
          </p>
        </section>
        <Stack direction="row" margin={6} spacing={2}>
          {cards
            .filter(card => card.active)
            .map(card => user && user.hasAnyOfTheseSsoRoles(...card.roles) ? (
              card.card
            ) : null)}
        </Stack>
      </main>
    </>
  )
}
