
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Column, useFilters, useTable } from "react-table";
import Restricted from "@permission/Restricted";
import { Pagination, IconButton, PaginatorPage, Table } from "@common/components";
import { useGridView, useTableFilters, useOpenDialogColumns } from "@common/context/GridView/useGridView";
import { GridColumnsDialog } from "./GridColumnsDialog";
export interface IPaginatedQueryOptions {
  before?: string;
  after?: string;
  first?: number;
  last?: number;
  filter?: any;
}

export interface FilteredColumn {
  id: string;
  value: any;
}

interface GridViewProps<T> {
  hook: any;
  columns: Column<T | any>[];
  defaultFilters?: FilteredColumn[];
  editRecord?: Function;
  deleteRecord?: Function;
  showRecord?: Function;
  paginated?: boolean;
  accessControlResourceName?: string;
  tableIdentifier?: string;
  hiddenColumns?: string[];
}

export function GridView<T>({
  hook,
  columns,
  defaultFilters,
  editRecord,
  deleteRecord,
  showRecord,
  paginated = false,
  accessControlResourceName,
  tableIdentifier,
  hiddenColumns,
}: GridViewProps<T>) {
  const [filters, setFilters] = useState<FilteredColumn[]>(
    defaultFilters || []
  );
  
  const { status, data, error, isFetching, refetch } = hook({
    gridFilters: filters,
  });

  const { gridFilters, setItems } = useGridView();
  const { openDialogColumns, setOpenDialogColumns } = useOpenDialogColumns()
  const { setTableFilters } = useTableFilters();

  const numberPage = useCallback((value: number) => {
    setFilters([...filters, {id: "currentPage", value}]);
  },[ filters ]);

  const crudButtons = () => {
    return {
      id: "crud",
      Cell: ({ row }: any) => (
        <div className="flex gap-x-4">
          {showRecord ? (
            <Restricted to={`show.${accessControlResourceName}`}>
              <IconButton
                title="Ver registro"
                onClick={() => showRecord(row)}
              >
                <VisibilityIcon />
              </IconButton>
            </Restricted>
          ) : null}
          {editRecord ? (
            <Restricted to={`edit.${accessControlResourceName}`}>
              <IconButton
                title="Editar registro"
                onClick={() => editRecord(row)}
              >
                <EditIcon />
              </IconButton>
            </Restricted>
          ) : null}
          {deleteRecord ? (
            <Restricted to={`delete.${accessControlResourceName}`}>
              <IconButton
                title="Excluir registro"
                onClick={() =>
                  deleteRecord(row).then((success: any) =>
                    success ? refetch() : null
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </Restricted>
          ) : null}
        </div>
      ),
    }
  };

  const plugins: any[] = [useFilters];

  plugins.push((hooks: any) => {
    hooks.visibleColumns.push((columns: any) => {
      let  visibleColumns = [ ...columns];
      if (showRecord || editRecord || deleteRecord) {
        visibleColumns.push(crudButtons());
      }

      return visibleColumns;
    })
  });

  useEffect(() => {
    setItems(data)
  }, [data, setItems])

  const columnHide = localStorage.getItem(`${tableIdentifier}-hidden-columns`);
  const loadInitialHiddenColumns = columnHide !== null
    ? JSON.parse(columnHide)
    : hiddenColumns || [];

  const tableInstance = useTable(
    {
      columns,
      data: (data?.edges || []),
      manualFilters: true,
      initialState: { filters, hiddenColumns: loadInitialHiddenColumns },
      autoResetHiddenColumns: false,
    } as any,
    ...plugins,
  );

  useEffect(() => {

    let tableFilters = new Map<string, string>();

    (tableInstance.state as any).filters.forEach((value: FilteredColumn) => tableFilters.set(value.id, value.value));

    gridFilters.forEach((value: FilteredColumn) => tableFilters.set(value.id, value.value));

    let updateFilters = Array.from(tableFilters, ([name, value]) => ({ 'id': name, value }));

    setFilters(updateFilters);

    setTableFilters(updateFilters);

  }, [tableInstance.state, setFilters, gridFilters]);

  return (
    <>
      <GridColumnsDialog open={openDialogColumns} onHandleClick={setOpenDialogColumns} tableInstance={tableInstance} tableIndentifier={tableIdentifier} />
      {data?.pageInfo && (data?.pageInfo?.total || 0) > 0 ? (
        <div className="mb-4">
          <PaginatorPage { ...data.pageInfo } />
        </div>
      ) : null}

      <Grid container spacing={3}>
        <Grid item xs={true}>
          <Table
            status={status}
            isFetching={isFetching}
            error={error as any}
            tableInstance={tableInstance}
          />
        </Grid>
      </Grid>

      {paginated && data?.pageInfo && data?.pageInfo.lastPage > 1 ? (
        <Pagination page={data?.pageInfo.currentPage || 1} count={data?.pageInfo.lastPage} setPage={numberPage} />
      ) : null}

    </>
  );
}
