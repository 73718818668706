export const MaskCnpj = ({ value }: { value?: string }) => {
  if (value && value.length >= 14) {
    const striped = value.replace(/\D/g, "");
    return (
      <>
        {striped.substr(0, 2) +
          "." +
          striped.substr(2, 3) +
          "." +
          striped.substr(5, 3) +
          "/" +
          striped.substr(8, 4) +
          "-" +
          striped.substr(12, 2)}
      </>
    );
  }
  return <>{value}</>;
};
