import { gql } from "graphql-request";

export interface ExportReimbursementSheetInput {
  input: {
    email: string;
    conciliationId: string;
  }
}

export async function sendExportReimbursementSheet(options: ExportReimbursementSheetInput, request: any) {

  const { exportReimbursementSheet } = await request(
    gql`
    mutation exportReimbursementSheet($input:ExportReimbursementSheetInput!) {
      exportReimbursementSheet(input:$input)
      }`, options
  )

  return exportReimbursementSheet
}
